import React from 'react';
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Link, graphql } from 'gatsby';
import Divider from '../sections/akkadian/Divider';
import { Helmet } from "react-helmet";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { height } from 'styled-system';

const BlogPostTemplate = ({ data }) => {
  const post = data.contentfulPageBlogPost;

  const renderOptions = {
    renderNode: {
        /**
         * Render embedded asset block (images, videos, etc.)
         */
        "embedded-asset-block": node => {
          const { gatsbyImageData } = node.data.target
          if (!gatsbyImageData) {
            // asset is not an image
            return null
          }
          return <GatsbyImage image={gatsbyImageData} />
        },
        /**
         * Render embedded entry block (e.g. a blog post)
         * NOTE: Contentful uses this block for embedded entries with images
         */
        "embedded-entry-block": node => {
            const { gatsbyImageData } = node.data.target.image
            if (!gatsbyImageData) {
              // asset is not an image
              return null
            }
            // render and style the image via GatsbyImage
            return <GatsbyImage image={gatsbyImageData} style={{ marginTop: "30px", marginBottom: "30px", marginLeft: "auto", marginRight: "auto", display: "block", width: "70%"}}/>
          }
      },
  };

return (
  <>
    <PageWrapper
    headerConfig={{ theme: "light", buttonText: "Contact us" }}
    footerConfig={{ theme: "light", page: "akkadian-home", style: "style2" }}
    >
    <Helmet>
        <title>{post.seoFields.pageTitle}</title>
    </Helmet>

    <Container className="justify-content-left mt-9 pt-25">
        <Row className="overflow-hidden rounded-20 border border-gray300 shadow-lg mx-2">
            <Col md={6} lg={6}>

                <Row className="mt-6 py-6">
                    <Col className="text-left" xs={6} sm={6} md={6} lg={6}>
                    <p className="d-flex align-items-center mb-0">
                        <GatsbyImage
                    image={getImage(post.author.avatar)} className="rounded-circle" style={{width: "30px", height: "30px"}} alt={post.author.name} />
                        <span className="text-muted small pl-2" style={{fontSize: "0.6rem"}}>
                            {post.author.name.toUpperCase()}
                        </span>
                    </p>
                    </Col>

                    <Col className="text-right" xs={6} sm={6} md={6} lg={6}>
                        <p className="text-muted small mt-2" style={{fontSize: "0.6rem"}}>
                        <span className="text-dark">Published:</span> {post.publishedDate}
                        </p>
                    </Col>
                </Row>
                <Row className="mx-6 mt-6 py-6">
                <h1 className="title gr-text-5 mb-9 mb-lg-12">{post.title}</h1>
                <p className="gr-text-9 mt-2">
                    {post.shortDescription.shortDescription}
                </p>
                </Row>


            </Col>
            <Col md={6} lg={6} style={typeof window !== "undefined" && window.innerWidth < 768 ? {paddingLeft: 0, paddingRight: 0} : {paddingRight: 0}}>
                    <GatsbyImage
                    image={getImage(post.featuredImage)}
                    alt={post.featuredImage.title}
                    style={{ width: "100%", height: "100%" }}
                    className={`w-100${typeof window !== "undefined" && window.innerWidth < 768 ? "rounded-bottom-20" : " rounded-right-20"}`}
                    />
            </Col>
        </Row>
    </Container>

    <div className="inner-banner">
        <Container>
{/*         <Row className="justify-content-left mt-9 pt-25">
            <Col>
                <h1 className="title gr-text-5 mt-27 mt-lg-36">{post.title}</h1>
            </Col>
            <Col>
                <GatsbyImage
                image={getImage(post.featuredImage)}
                alt={post.featuredImage.title}
                style={{ width: "100%" }}
                />
            </Col>
        </Row>
        <Row className="justify-content-left mt-6 pt-12">
            <Col>
                <h1 className="title gr-text-5 mb-9 mb-lg-12">{post.title}</h1>
            </Col>
        </Row> */}


        <Row className="justify-content-left mt-12">

        {/* {post.content.raw}*/}

            <Col className="gr-text-6 ml-3">
                {post.content && renderRichText(post.content, renderOptions)}
            </Col>
        </Row>

{/*         <Row className="justify-content-left mb-9 mb-lg-12">
            <Col>
                <p className="mt-7 text-muted small">
                    <span className="text-dark">By:</span> {post.author.name}
                </p>
                    
                <p className="mt-3 text-muted small">
                    <span className="text-dark">Published On:</span> {post.publishedDate}
                </p>
            </Col>
        </Row> */}

        </Container>
    </div> 

    <Container>
        <Row className="justify-content-center mt-10 mb-10">
            <Col lg="6" xl="5">
                <div className="text-center">
                    <Link to="/blog"
                        className="btn btn-blue gr-hover-y">
                        Back to Latest Articles
                    </Link>
                </div>
            </Col>
        </Row>
    </Container>
    <Divider />

    </PageWrapper>



    </>
    );
};



export const query = graphql`
  query($id: String!) {
    contentfulPageBlogPost(id: { eq: $id }) {
      title
      shortDescription {
        shortDescription
      }
        seoFields {
        pageTitle
        nofollow
        noindex
      }
      author {
        name
        avatar {
          gatsbyImageData(width: 200)
          file {
            url
          }
        }
      }
      publishedDate(formatString: "MMM Do, YYYY")
      title
      featuredImage {
        title
        description
        gatsbyImageData(layout: FULL_WIDTH)
      }
      content {
        raw
        references {
            ... on ContentfulComponentRichImage {
                contentful_id
                image {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
              }
        }
      }
    }
  }
`;

export default BlogPostTemplate;